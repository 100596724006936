import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import uniqueId from "lodash/uniqueId";
import { TestimonialsItem } from "./types";
import * as Styled from "./styles";
import IconPagination from "../../assets/icon-pagination.inline.svg";
import IconPaginationPrevious from "../../assets/icon-pagination-previous.inline.svg";
import IconPaginationNext from "../../assets/icon-pagination-next.inline.svg";
import useVisibleOnScroll from "../../hooks/useVisibleOnScroll";

interface TestimonialsProps {
  data: TestimonialsItem[];
  title: string;
}

interface TestimonialsDataItem extends TestimonialsItem {
  id: string;
}

export const TestimonialsMore = ({
  data,
  title,
}: TestimonialsProps): ReactElement => {
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): TestimonialsDataItem => ({
          ...item,
          id: uniqueId(),
        })
      ),
    [data]
  );

  //const [activeQuote, setActiveQuote] = useState(memoizedData[0].id);
  const testimonialsRef = useRef(null);
  const { isVisible } = useVisibleOnScroll(testimonialsRef);

  const testimonialSlide = [{ id: 1 }, { id: 2 }, { id: 3 }];
  const [activeSlide, setActiveSlide] = useState(testimonialSlide[0].id);
  const getActiveSlideIndex = useCallback(
    () => testimonialSlide.findIndex(({ id }) => id === activeSlide),
    [activeSlide]
  );
  const handlePrevClick = useCallback(() => {
    const activeSlideIndex = getActiveSlideIndex();

    if (activeSlideIndex === 0) {
      setActiveSlide(testimonialSlide[testimonialSlide.length - 1].id);
    } else {
      setActiveSlide(testimonialSlide[activeSlideIndex - 1].id);
    }
  }, [activeSlide, getActiveSlideIndex]);

  const handleNextClick = useCallback(() => {
    const activeSlideIndex = getActiveSlideIndex();

    if (activeSlideIndex === testimonialSlide.length - 1) {
      setActiveSlide(testimonialSlide[0].id);
    } else {
      setActiveSlide(testimonialSlide[activeSlideIndex + 1].id);
    }
  }, [activeSlide, getActiveSlideIndex]);

  return (
    <Styled.Wrapper isVisible={isVisible} ref={testimonialsRef}>
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Heading>

      <Styled.GridMore isActive={1 === activeSlide}>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(0, 2).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(2, 5).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(5, 7).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(7, 9).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(9, 12).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(12, 15).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(15, 17).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(17, 19).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(19, 21).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(21, 24).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(24, 28).map(({ id, image }) => (
              <Styled.ImageContainer width="24%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(28, 31).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(31, 35).map(({ id, image }) => (
              <Styled.ImageContainer width="24%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
      </Styled.GridMore>

      <Styled.GridMore isActive={2 === activeSlide}>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(35, 38).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(38, 40).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(40, 43).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(43, 46).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(46, 49).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(49, 52).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(52, 55).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(55, 58).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(58, 61).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(61, 64).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row hideSmallScreen={true}>
          <Styled.Col size={1}>
            {memoizedData.slice(64, 67).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row hideSmallScreen={true}>
          <Styled.Col size={1}>
            {memoizedData.slice(67, 70).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
      </Styled.GridMore>

      <Styled.GridMore isActive={3 === activeSlide}>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(70, 73).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(73, 76).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(76, 80).map(({ id, image }) => (
              <Styled.ImageContainer width="49%">{image}</Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(80, 83).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(83, 86).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(86, 89).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(89, 92).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row hideSmallScreen={true}>
          <Styled.Col size={1}>
            {memoizedData.slice(92, 95).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(95, 98).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>

        <Styled.Row>
          <Styled.Col size={1}>
            {memoizedData.slice(98, 102).map(({ id, image }) => (
              <Styled.ImageContainer width="32.8%">
                {image}
              </Styled.ImageContainer>
            ))}
          </Styled.Col>
        </Styled.Row>
      </Styled.GridMore>

      <Styled.Doodle />
      <Styled.Arrow
        src="/testimonials-arrow.webp"
        srcSet="/testimonials-arrow.webp 1x, /testimonials-arrow@2x.webp 2x"
        alt="Arrow"
        loading="lazy"
      />

      {/* <Styled.TestimonialNav>
        <Styled.TestimonialNavDot id="tset1" key={1} type="button" isActive={true}>
            <IconPagination />
        </Styled.TestimonialNavDot>
        <Styled.TestimonialNavDot id="tset2" key={2} type="button">
            <IconPagination />
        </Styled.TestimonialNavDot>
    </Styled.TestimonialNav> */}

      <Styled.TestimonialNav>
        <Styled.TestimonialNavButton
          type="button"
          onClick={handlePrevClick}
          aria-label="Go to previous slide"
        >
          <IconPaginationPrevious />
        </Styled.TestimonialNavButton>
        {testimonialSlide.map(({ id }, index) => (
          <Styled.TestimonialNavDot
            isActive={id === activeSlide}
            key={id}
            onClick={() => setActiveSlide(id)}
            type="button"
            aria-label={`Go to testimonials ${index + 1}`}
          >
            <IconPagination />
          </Styled.TestimonialNavDot>
        ))}
        <Styled.TestimonialNavButton
          id="next"
          type="button"
          onClick={handleNextClick}
          aria-label="Go to next slide"
        >
          <IconPaginationNext />
        </Styled.TestimonialNavButton>
      </Styled.TestimonialNav>
    </Styled.Wrapper>
  );
};
